import "./App.css";

import MailchimpSubscribe from "react-mailchimp-subscribe";

import logo from "./assets/img/logo.png";
import island from "./assets/img/animated_final.png";
import island3 from "./assets/img/animated_final3.png";

import telegram from "./assets/img/telegram.svg";
import twitter from "./assets/img/twitter.svg";
import discord from "./assets/img/discord.svg";
import reddit from "./assets/img/reddit.svg";

const url = `https://infinityskies.us6.list-manage.com/subscribe/post?u=fe3bcaf91133d8ee475d6f162&id=67a7eadec0`;

// simplest form (only email)
const SimpleForm = () => <MailchimpSubscribe url={url} />;

function App() {
  return (
    <div className="App">
      <img className="logo" src={logo} alt="logo" />
      <div className="island">

        <picture>
          <source media="(min-width:768px)" srcSet={island3} />
          <img src={island} alt="Flowers" />
        </picture>

      </div>
      <div className="text">
        <h1>Welcome to Infinity Skies!</h1>
        <p>
          Build, trade, adventure and socialize in this groundbreaking
          play-to-earn Crypto Sandbox Game.
        </p>

        <div className="form-group">
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <div>
                <SimpleForm onSubmitted={(formData) => subscribe(formData)} />
                {status === "sending" && <div style={{ color: "#fff" }}>sending...</div>}
                {status === "error" && <div style={{ color: "#FF5C5C" }} dangerouslySetInnerHTML={{__html: message}}/>}
                {status === "success" && <div style={{ color: "#04D600" }}>Subscribed !</div>}

              </div>
            )}
          />
        </div>
        {/* 
        <form>
          <div className="form-group">
            <input className="input" placeholder="Your Email" />

            <button className="submit">
              Subscribe
            </button>
          </div>
        </form> */}

        <ul className="social">
          <li>
            <a href="https://twitter.com/inf_skies">
              <img src={twitter} alt="logo" />
            </a>
          </li>
          <li>
            <a href="https://www.reddit.com/r/InfinitySkies/">
              <img src={reddit} alt="logo" />
            </a>
          </li>
          <li>
            <a href="https://discord.com/invite/afjy7Wac2k">
              <img src={discord} alt="logo" />
            </a>
          </li>
          <li>
            <a href="https://t.me/InfinitySkies">
              <img src={telegram} alt="logo" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default App;
